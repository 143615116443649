<template>
    <div v-if="!successMessage" class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="page-title">Hiermit möchte ich einen Zugang zum Allianz Maklertools Portal beantragen.</div>
            </div>
        </div>
        <div class="row formRow">
            <div class="col-md-3">
                <v-select class="customTextBox" :items="salutation" @change="loseFocus" v-model="userdata.salutation" label="Anrede *" required></v-select>
            </div>
            <div class="col-md-3">
                <v-text-field label="Nachname *" class="customTextBox" light v-model="userdata.lastname" :rules="rules" required></v-text-field>
            </div>
            <div class="col-md-3">
                <v-text-field label="Vorname *" class="customTextBox" v-model="userdata.firstname" :rules="rules" required></v-text-field>
            </div>
            <div class="col-md-3">
                <v-text-field label="Geschäftliche E-Mail Adresse *" class="customTextBox" v-model="userdata.email" :rules="rules" required></v-text-field>
            </div>
        </div>
        <div class="row formRow">
            <div class="col-md-3">
                <v-text-field v-if="userdata.nrtype === 'vtnr'" label="Vermittlernummer *" :mask="vtnr" return-masked-value class="customTextBox" v-model="userdata.vtnr" :rules="rules" required></v-text-field>
                <v-text-field v-if="userdata.nrtype === 'regnr'" label="Registrierungsnummer *" :mask="regnr" class="customTextBox" v-model="userdata.vtnr" :rules="rules" required></v-text-field>
            </div>
            <div class="col-md-3">
                <v-text-field label="Maklerbüro *" class="customTextBox" v-model="userdata.company" :rules="rules" required></v-text-field>
            </div>
            <div class="col-md-3">
                <v-text-field label="ggf. Vertriebseinheit *" class="customTextBox" v-model="userdata.poolname"></v-text-field>
            </div>
        </div>
        <div class="row formRow">
            <div class="col-md-3">
                <v-text-field label="Straße *" class="customTextBox" light v-model="userdata.street" :rules="street" required></v-text-field>
            </div>
            <div class="col-md-3">
                <v-text-field label="Hausnummer *" class="customTextBox" light v-model="userdata.number" :rules="rules" required></v-text-field>
            </div>
            <div class="col-md-3">
                <v-text-field label="PLZ *" class="customTextBox" v-model="userdata.zip" :rules="rules" required></v-text-field>
            </div>
            <div class="col-md-3">
                <v-text-field label="Ort *" class="customTextBox" v-model="userdata.city" :rules="rules" required></v-text-field>
            </div>
        </div>

        <div class="row formRow">
            <div class="col-md-3">
                <v-text-field label="Passwort *" class="customTextBox" 
                    :append-icon="e1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :append-icon-cb="() => (e1 = !e1)" 
                    :type="e1 ? 'text' : 'password'"
                    light 
                    v-model="userdata.password" 
                    :rules="rules" 
                    @click:append="e1 = !e1"
                    required></v-text-field>
            </div>
            <div class="col-md-3">
                <v-text-field label="Passwort wiederholen *" class="customTextBox" 
                :append-icon="e2 ? 'mdi-eye' : 'mdi-eye-off'"
                :append-icon-cb="() => (e2 = !e2)" 
                :type="e2 ? 'text' : 'password'" 
                light 
                v-model="userdata.password_confirmation" 
                :rules="rules"
                @click:append="e2 = !e2" 
                required></v-text-field>
            </div>
        </div>
        <div class="row formRow">
            <div class="col-md-10">
                <span style="font-size:12px;"> Das Passwort sollte mindestens einen Großbuchstaben, einen Kleinbuchstaben, einen numerischen Wert und ein Sonderzeichen enthalten und muss länger als 8 Zeichen lang sein.</span>
            </div>
        </div>

        <hr />

        <div class="row">
            <div class="col-md-6">
                <div class="infobox">
                    <p>Gerne stellen wir Ihnen auch unsere Beratungssuite zur Erstellung von Beratungsseiten sowie unsere Filme zum Download zur Verfügung.</p>

                    <p>
                        <b>
                            Um Ihnen jeweils den Zugang zu ermöglichen, benötigen wir:<br />
                            1. für die Beratungssuite die unterschriebene <a  href="static/downloads/nutzungsbedingungen_beratungssuite.pdf" target="_blank" rel="noopener noreferrer" style="text-decoration: underline;">Nutzungsvereinbarung</a>
                            <v-tooltip bottom max-width="300">
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" text icon color="white">
                                        <v-icon dark>info</v-icon>
                                    </v-btn>
                                </template>
                                <span>Sie können in der Beratungssuite eigene Beratungsseiten für Ihre Onlineberatung zusammenstellen.</span>
                            </v-tooltip><br>
                            2. für die Filme den ausgefüllten und unterschriebenen <a  href="static/downloads/PDF_Filmnutzungsrechte.pdf" target="_blank" rel="noopener noreferrer" style="text-decoration: underline;">Lizenzvertrag</a>
                            <v-tooltip bottom max-width="300">
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" text icon color="white">
                                        <v-icon dark>info</v-icon>
                                    </v-btn>
                                </template>
                                <span>Sie können produkt- bzw. themenspezifische Filme downloaden und zur Kundenansprache, bspw. durch Einbindung auf Ihrer Homepage, nutzen.</span>
                            </v-tooltip>
                            <br>
                        </b>
                    </p>

                    <p>Schicken Sie uns den jeweiligen Vertrag per Mail an <a href="mailto:mv-zugang@allianz.de" style="text-decoration: underline;">mv-zugang@allianz.de</a>.</p>
                </div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <vue-recaptcha style="width: 100%; display: flex; justify-content: flex-end;" :loadRecaptchaScript="true" ref="recaptcha" @verify="onVerify" @expired="onExpired" sitekey="6Le1XkYUAAAAAONwMr7tgshbd15lV14YQPvUtPNY"></vue-recaptcha>
                <!-- <vue-recaptcha style="width: 100%;" ref="recaptcha" @verify="onVerify" @expired="onExpired" sitekey="6LcDTJsUAAAAANvb_3QtxMDKxT0X_mOyY71pOC3g"></vue-recaptcha> -->
                <div class="buttonHolder">
                    <div v-if="errorMessage" class="errorMessage">{{ errorMessage }}</div>
                    <button v-if="loading" class="send disabled" disabled>Senden</button>
                    <button v-else class="send" @click="submit">Senden</button>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="container successMessage">
        <div class="row">
            <div class="col-md-12" v-html="successMessage"></div>
        </div>

        <div class="row">
            <div class="col-md-12"><router-link class="back" to="/">Zurück zum Login</router-link></div>
        </div>
    </div>

</template>

<script>
/* eslint-disable */
import VueRecaptcha from 'vue-recaptcha';
import config from '@/config.js';

export default {
    name: 'Register',
    data() {
        return {
            salutation: [
                'Herr',
                'Frau'
            ],
            nrtypes: [
                {
                    text: 'Vermittlernummer',
                    value: 'vtnr'
                },
                {
                    text: 'Registrierungsnummer',
                    value: 'regnr'
                }
            ],
            rules: [
                v => !!v || 'Dieses Feld wird benötigt'
            ],
            street: [
                v => !!v || 'Dieses Feld wird benötigt',
                v => /^([^0-9]*)$/.test(v) || 'Zahlen sind in Straßennamen nicht erlaubt.'
            ],
            vtnr: '##/###/####',
            regnr: 'D-NNNN-NNNNN-NN',
            e1: false,
            e2: false,
            captcha: false,
            errorMessage: null,
            successMessage: null,
            loading: false,
            userdata: {
                salutation: '',
                lastname: '',
                firstname: '',
                email: '',
                nrtype: 'vtnr',
                vtnr: '',
                company: '',
                poolname: '',
                street: '',
                number: '',
                zip: '',
                city: '',
                password: '',
                password_confirmation: '',

            }
        }
    },
    components: { VueRecaptcha },
    methods: {
        loseFocus() {
            this.$nextTick(() => {
                this.$children
                    .filter(e => e.$options.name === 'v-select')
                    .forEach(e => $(e.$el).removeClass('input-group--focused'));
            });
        },
        submit() {
            if (this.captcha || true) {
                this.errorMessage = null;
                this.loading = true;

                $.post(`${config.BaseURL}api/login/register`, {
                  ...this.userdata,
                  captcha: this.captcha
                }).then((res) => {
                    this.errorMessage = null;
                    this.loading = false;

                    this.successMessage = `<p>Sehr ${this.userdata.salutation === 'Herr' ? 'geehrter' : 'geehrte'} ${this.userdata.salutation} ${this.userdata.firstname} ${this.userdata.lastname},</p>

                    <p>damit Ihr Antrag weiter bearbeitet werden kann, erhalten<br />
                    Sie in Kürze eine E-Mail mit einem Link zur Bestätigung.</p>

                    <p>Sollten Sie die E-Mail nicht erhalten haben, <br/>
                    überprüfen Sie bitte auch Ihren Spam-Ordner.</p>

                    <p>Mit freundlichem Gruß<br />
                    Allianz Maklertools Portal</p>`;
                }, (error) => {
                    if (error.responseJSON && error.responseJSON.errors) {
                        let messages = Object.keys(error.responseJSON.errors).map(key => error.responseJSON.errors[key][0]);

                        this.errorMessage = messages[0];
                    } else {
                        this.errorMessage = 'Es ist ein Problem aufgetreten. Versuchen Sie es später noch einmal.';
                    }

                    this.loading = false;
                });
            } else {
                this.errorMessage = 'Sie müssen das Captcha lösen um fortzufahren.';
            }
        },
        onVerify(res) {
            this.captcha = res;
        },
        onExpired() {
            this.captcha = false;
        }
    }
}
</script>

<style lang="scss">
.customTextBox {
    .input-group__selections__comma {
        padding-left: 5px;
    }
    .input-group__input {
        background-color: rgba(255, 255, 255, 0.7);

        input {
            padding-left: 10px;
        }
    }

    label {
        left: 5px !important;
    }
}

.customTextBox.error--text {
    color: #b10000 !important;
}

.customTextBox.primary--text {
    color: #003781 !important;
}

</style>

<style lang="scss" scoped>
.specialRow {
    margin: 0;
}

.form-label {
    font-size: 15px;
}

hr {
    width: 100%;
    height: 1px;
    border: 0;
    background-color: #717171;
    margin-bottom: 20px;
}

.send {
    background-color: #003781;
    border: 0;
    color: #ffffff;
    padding: 5px 15px;
    float: right;
    margin-right: 3px;
    margin-top: 5px;
    margin-bottom: 20px;
}

.buttonHolder {
    margin-top: 5px;
}

.errorMessage {
    width: 100%;
    text-align: right;
    color: rgb(180, 0, 0);
    font-weight: bold;
}

.successMessage .back {
    margin-top: 10px;
    display: inline-block;
    color: #337ab7;
}

.disabled {
    opacity: 0.7;
}

.infobox {
    background-color: #7488a3;
    padding: 10px;
    color: white;
    margin-bottom: 25px;

    a {
        color: white;
    }
}

.page-title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
}
.second-row {
    margin-top: 20px;
    margin-bottom: 40px;
}

.combinedLeft {
    padding-right: 1px;
}

.combinedRight {
    padding-left: 1px;
}

@media screen and (min-width: 1216px) {
    .input-sml {
        display:inline;
        width: 25%;
        margin-left:55px;
    }

    .form-label {
        display:inline;
        position: relative;
        top: 4px;
        font-weight: bold;
    }
    .form-label-inner {
        display:inline;
        position: relative;
        font-weight: bold;
        max-width:100px;
    }
    input[type="text"], input[type="password"] {
        border: 0;
        background-color: rgba(255,255,255,0.7);
        padding: 5px 10px;
    }
    .input {
        float: right;
    }
    .vermittlernummer {
        max-width:150px;
    }
    .email-label {
        width:10%;
    }
    .input-email {
        width: 80%;
    }
    .vorname {
        margin-left:15px;
    }
    .input-sml-vorname {
        display:inline;
        width: 35%;
        margin-left:20px;
    }
    input[type=button] {
        background-color: #003781;
        border: 0;
        color: #ffffff;
        padding: 5px 15px;
    }
}
</style>
