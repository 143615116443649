<template>
    <div class="admin">
      <div class="actions">
        <v-btn @click="$router.push({name: 'admin.index'})" color="error" fab small class="mx-2">
          <v-icon>keyboard_backspace</v-icon>
        </v-btn>

        <v-btn @click="$router.push({name: 'admin.createUser'})" color="success" fab small class="mx-2">
          <v-icon>add</v-icon>
        </v-btn>
      </div>
      <v-row>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card class="viewDialog">
            <v-card-title>
              <span class="headline">Nutzerinformationen</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-row wrap>
                  <v-col sm="6" md="4">
                    <v-text-field label="Anrede" v-model="editedItem.salutation" disabled></v-text-field>
                  </v-col>
                  <v-col sm="6" md="4">
                    <v-text-field label="Vorname" v-model="editedItem.firstname" disabled></v-text-field>
                  </v-col>
                  <v-col sm="6" md="4">
                    <v-text-field label="Nachname" v-model="editedItem.lastname" disabled></v-text-field>
                  </v-col>
                  <v-col sm="6" md="8">
                    <v-text-field label="Straße" v-model="editedItem.street" disabled></v-text-field>
                  </v-col>
                  <v-col sm="6" md="4">
                    <v-text-field label="Hausnummer" v-model="editedItem.number" disabled></v-text-field>
                  </v-col>
                  <v-col sm="6" md="4">
                    <v-text-field label="PLZ" v-model="editedItem.zip" disabled></v-text-field>
                  </v-col>
                  <v-col sm="6" md="8">
                    <v-text-field label="Ort" v-model="editedItem.city" disabled></v-text-field>
                  </v-col>
                  <v-col sm="6" md="4">
                    <v-text-field label="Maklerbüro" v-model="editedItem.company" disabled></v-text-field>
                  </v-col>
                  <v-col sm="6" md="4">
                    <v-text-field label="Vertriebseinheit" v-model="editedItem.poolname" disabled></v-text-field>
                  </v-col>
                  <v-col sm="6" md="4" v-if="editedItem.nrtype === 'regnr'">
                    <v-text-field label="Registrierungsnr." v-model="editedItem.vtnr" disabled></v-text-field>
                  </v-col>
                  <v-col sm="6" md="4" v-if="editedItem.nrtype === 'vtnr'">
                    <v-text-field label="Vermittlernummer" v-model="editedItem.vtnr" disabled></v-text-field>
                  </v-col>
                  <v-col sm="12" md="12">
                  <v-text-field label="E-Mail" v-model="editedItem.email" disabled></v-text-field>
                  </v-col>
                  <v-col sm="12" md="12">
                   <v-checkbox v-model="editedItem.confirmed" disabled label="E-Mail bestätigt"></v-checkbox>
                  </v-col>
                  <v-col sm="12" md="12">
                  <v-checkbox v-model="editedItem.allowed" disabled label="Portalzugriff freigeschaltet"></v-checkbox>
                  </v-col>
                  <v-col sm="12" md="12">
                 <v-checkbox v-model="editedItem.signed" disabled label="Lizenzvertrag Filme unterschrieben"></v-checkbox>
                  </v-col>
                  <v-col sm="12" md="12">
                  <v-checkbox v-model="editedItem.admin" disabled label="Administrator"></v-checkbox>
                  </v-col>
                  <v-col sm="12" md="12">
                Beratungssuite Berechtigungen
                  </v-col>
                  <v-col sm="12" md="12">
                    <hr />
                  </v-col>
                  <v-col sm="12" md="12">
                    <v-checkbox v-model="editedItem.signed_makler" disabled label="Lizenzvertrag Beratungssuite unterschrieben"></v-checkbox>
                  </v-col>
                  <v-col sm="12" md="12">
                    <v-checkbox v-model="editedItem.reporting" disabled label="Zugriff aufs Reporting"></v-checkbox>
                  </v-col>
                  <v-col sm="12" md="12">
                    <v-checkbox v-model="editedItem.templates" disabled label="Darf Vorlagen erstellen"></v-checkbox>
                  </v-col>
                  <v-col sm="12" md="12">
                    <v-checkbox v-model="editedItem.tools" disabled label="Darf Bausteine verwalten"></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click.native="close">Okay</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="beratungssuitedialog" max-width="500px">
          <v-card class="viewDialog">
            <v-card-title>
              <span class="headline">Beratungssuite Berechtigungen</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-row wrap>
                  <v-col sm="6" md="4">
                    <v-text-field label="Anrede" v-model="editedItem.salutation" disabled></v-text-field>
                  </v-col>
                  <v-col sm="6" md="4">
                    <v-text-field label="Vorname" v-model="editedItem.firstname" disabled></v-text-field>
                  </v-col>
                  <v-col sm="6" md="4">
                    <v-text-field label="Nachname" v-model="editedItem.lastname" disabled></v-text-field>
                  </v-col>
                  <v-col sm="12" md="12">
                    <v-text-field label="E-Mail" v-model="editedItem.email" disabled></v-text-field>
                  </v-col>

                  <v-col sm="12" md="12">
                    <v-select
                      v-model="editedItem.group_ids"
                      :items="groupItems"
                      attach
                      chips
                      label="Gruppen"
                      multiple
                      @change="changedGroups"
                    ></v-select>
                  </v-col>

                  <v-col sm="12" md="6">
                    Lizenzvertrag Beratungssuite unterschrieben:
                  </v-col>
                  <v-col sm="12" md="6">
                    <v-switch v-model="editedItem.signed_makler" @change="changed('signed_makler', editedItem.signed_makler, editedItem.id)"></v-switch>
                  </v-col>
                  <v-col sm="12" md="6">
                    Zugriff aufs Reporting:
                  </v-col>
                  <v-col sm="12" md="6">
                    <v-switch v-model="editedItem.reporting" @change="changed('reporting', editedItem.reporting, editedItem.id)"></v-switch>
                  </v-col>
                  <v-col sm="12" md="6">
                    Darf Vorlagen erstellen:
                  </v-col>
                  <v-col sm="12" md="6">
                    <v-switch v-model="editedItem.templates" @change="changed('templates', editedItem.templates, editedItem.id)"></v-switch>
                  </v-col>
                  <v-col sm="12" md="6">
                    Darf Bausteine verwalten:
                  </v-col>
                  <v-col sm="12" md="6">
                    <v-switch v-model="editedItem.tools" @change="changed('tools', editedItem.tools, editedItem.id)"></v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click.native="close">Okay</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-col sm="12">
          <v-card v-if="!usersLoading">
            <v-card-title>
              Nutzer
              <v-spacer></v-spacer>
              <v-text-field
                append-icon="search"
                label="Suchen"
                single-line
                hide-details
                v-model="search"
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="users"
              :search="search"
              class="elevation-1"
              :footer-props="{ 
                'items-per-page-options': [20,50], 
                'items-per-page-text': 'Zeilen pro Seite'
              }"
              no-data-text="Keine Nutzer vorhanden"
              no-results-text="Keine Ergebnisse"
            >
              <!-- <template slot="items" slot-scope="props"> -->
                <template v-slot:item="props">
                  <tr>
                    <td class="colWidth">{{ props.item.vtnr }}</td>
                    <td class="text-xs-left colWidth">{{ props.item.firstname }}</td>
                    <td class="text-xs-left colWidth">{{ props.item.lastname }}</td>
                    <td class="text-xs-left colWidth">{{ props.item.email }}</td>
                    <td class="text-xs-left colWidth">
                      <v-switch v-model="props.item.confirmed" @change="changed('confirmed', props.item.confirmed, props.item.id)"></v-switch>
                    </td>
                    <td class="text-xs-left colWidth">
                      <v-switch v-model="props.item.allowed" @change="changed('allowed', props.item.allowed, props.item.id)"></v-switch>
                    </td>
                    <td class="text-xs-left colWidth">
                      <v-switch v-model="props.item.signed" @change="changed('signed', props.item.signed, props.item.id)"></v-switch>
                    </td>
                    <td class="text-xs-left colWidth" v-if="userData.superadmin">
                      <v-switch v-model="props.item.admin" @change="changed('admin', props.item.admin, props.item.id)"></v-switch>
                    </td>
                    <td class="justify-center px-0">
                      <v-btn icon class="mx-0" @click="beratungssuite(props.item)">
                        <v-icon color="primary">dashboard</v-icon>
                      </v-btn>
                      <v-btn icon class="mx-0" @click="info(props.item)">
                        <v-icon color="warning">info</v-icon>
                      </v-btn>
                      <v-btn icon class="mx-0" @click="edit(props.item.id)">
                        <v-icon color="teal">edit</v-icon>
                      </v-btn>
                      <v-dialog v-model="props.item.dialog" persistent max-width="290">
                          <template v-slot:activator="{ on }">
                            <v-btn class="mx-0" icon v-on="on">
                                <v-icon color="error">delete</v-icon>
                            </v-btn>
                          </template>
                          <v-card>
                              <v-card-title class="headline">Möchtest du den Nutzer<br />{{ props.item.firstname }} {{ props.item.lastname }} wirklich löschen?</v-card-title>
                              <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="red darken-1" text @click.native="props.item.dialog = false;">Nein</v-btn>
                              <v-btn color="green darken-1" text @click.native="props.item.dialog = false; deleteItem(props.item.id);">Ja</v-btn>
                              </v-card-actions>
                          </v-card>
                      </v-dialog>
                    </td>
                </tr>
              </template>
              <template slot="pageText" slot-scope="props">
                {{ props.pageStart }} - {{ props.pageStop }} von {{ props.itemsLength }}
              </template>
            </v-data-table>
          </v-card>
          <v-progress-circular v-else indeterminate color="allianz" class="mx-auto loading"></v-progress-circular>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import config from '@/config.js';

export default {
  name: 'Users',
  data() {
    return {
      config,
      search: '',
      dialog: false,
      beratungssuitedialog: false,
      editedItem: {},
    }
  },
  computed: {
    ...mapGetters([
      'userData',
      'users',
      'usersLoading',
      'groups'
    ]),
    groupItems() {
      if (!this.groups) {
        return [];
      }

      return this.groups.map(group => {
        return {
          text: group.name,
          value: group.id
        };
      });
    },
    headers() {
      let headers = [
        { text: 'Nummer', value: 'vtnr', class: 'smallFont' },
        { text: 'Vorname', value: 'firstname', class: 'smallFont'},
        { text: 'Name', value: 'lastname', class: 'smallFont' },
        { text: 'E-Mail', value: 'email', class: 'smallFont' },
        { text: 'E-Mail bestätigt', value: 'confirmed', class: 'smallFont' },
        { text: 'Portalzugriff freigeschaltet', value: 'allowed', class: 'smallFont' },
        { text: 'Lizenzvertrag für Filme unterschrieben', value: 'signed', class: 'smallFont' }
      ];

      if (this.userData.superadmin) {
        headers.push({ text: 'Admin', value: 'admin', class: 'smallFont' });
      }

      headers.push({ text: 'Aktionen', value: 'actions', class: 'smallFont' });

      return headers;
    },
    filteredItems() {
      if (this.search) {
        return this.users.filter(e => {
          let keys = Object.keys(e);
          for (let i = 0; i < keys.length; i++) {
            if ((typeof e[keys[i]] === 'string') && e[keys[i]].toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
              return true;
            }
          }

          return false;
        });
      }

      return this.users;
    }
  },
  methods: {
    changedGroups(group_ids) {
      $.post(config.BaseURL + 'api/users/setgroupids/' + this.editedItem.id, {
        group_ids
      });
    },
    beratungssuite(item) {
      this.editedItem = item;
      this.beratungssuitedialog = true;
    },
    info(item) {
      this.editedItem = item;
      this.dialog = true;
    },
    close() {
        this.dialog = false;
        this.beratungssuitedialog = false;

        setTimeout(() => {
          this.editedItem = {};
        }, 300);
    },
    deleteItem(id) {
        $.get(config.BaseURL + 'api/users/delete/' + id).then(() => {
          this.$store.commit('getUsers');
        }, () => {});
    },
    edit(id) {
        this.$router.push({
            name: 'admin.editUser',
            params: {
                id
            }
        });
    },
    changed(prop, value, id) {
      $.post(config.BaseURL + 'api/users/update', {
        prop,
        value,
        id
      });
    }
  },
  created() {
    if (!this.userData || this.userData.admin !== 1) {
      this.$router.push('/');
    }

    this.$store.commit('setCurrentPage', 'portal');
    this.$store.commit('getUsers');
    this.$store.commit('getGroups');
  }
}
</script>

<style lang="scss">
  *:focus {
    outline: 0 !important;
  }

  .actions .icon {
    font-size: 22px !important;
  }

  .admin .datatable__actions__select {
    width: 200px;
  }

  .moreLines {
    white-space: normal !important;
  }

  .smallFont span {
    font-size: 13px !important;
    font-weight: 400;
    white-space: normal !important;
  }

  .colWidth {
    width: 10% !important;
  }

  .viewDialog {
    .grid-list-md {
      width: 100%;
    }

    label {
      color: #d1d1d1 !important;
    }

    .input-group--dirty label, .input-group--dirty input {
      color: black !important;
    }

    .input-group__details:before {
      background-color: black !important;
    }
  }
</style>

<style lang="scss" scoped>
  .admin {
    width: 100%;
    padding: 0px 15px;
  }

  .actions {
    margin-top: 20px;
    margin-bottom: 20px;
    z-index:1000;
  }

  .actions > * {
    font-size: 16px !important;
  }

  .loading {
    display: block !important;
    height: 70px !important;
  }
</style>
